// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "fb_l1";
export var caseStudyVideoReview__bgRings = "fb_JR";
export var caseStudyVideoReview__clientImage = "fb_JT";
export var caseStudyVideoReview__container = "fb_JQ";
export var caseStudyVideoReview__logo = "fb_J3";
export var caseStudyVideoReview__name = "fb_J4";
export var caseStudyVideoReview__playWrapper = "fb_JH";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "fb_JK";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "fb_JP";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "fb_JM";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "fb_JN";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "fb_JJ";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "fb_JL";
export var caseStudyVideoReview__text = "fb_J5";
export var caseStudyVideoReview__title = "fb_JS";
export var caseStudyVideoReview__videoYoutube = "fb_J2";
export var caseStudyVideoReview__wrapper = "fb_JV";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "fb_JY";
export var caseStudyVideoReview__wrapper_videoReviewIx = "fb_J1";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "fb_JZ";
export var caseStudyVideoReview__wrapper_videoReviewRef = "fb_J0";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "fb_JW";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "fb_JX";